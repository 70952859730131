/**
 * Todo:
 * Create a base stylesheet base.css
 * Create profiles topositus.css, simonfo.css, etc.
 * The base sheet sets the font, styles for bullet points, etc.
 * The profiles set the colors, and perhaps override the fonts, etc.
 */

import './styles/base.css';
import './styles/simonfo.css';
import './styles/topositus.css';
import './styles/responsive-font-sizes.css';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
